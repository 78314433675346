import Vue from "vue";
import Vuex from "vuex";
import i18n from "../i18n";

Vue.use(Vuex);

import dashboard from "./dashboard";
import source from "@/axios";
import isEmpty from "lodash/isEmpty";
import appSettings from "@/app-setting";

const store = new Vuex.Store({
    state: {
        layout: "main",
        is_show_sidebar: true,
        is_show_search: false,
        is_dark_mode: false,
        dark_mode: "light",
        locale: null,
        menu_style: "vertical",
        layout_style: "full",
        isLogged: false,
        managers: null,
        messengers: null,
        account: null,
        balance: null,
        iabCategories: null,
        domains: {safe_domain: null, promo_domain: null, cdn_domain: null},
        countryList: [
            { code: "zh", name: "Chinese" },
            { code: "da", name: "Danish" },
            { code: "en", name: "English" },
            { code: "fr", name: "French" },
            { code: "de", name: "German" },
            { code: "el", name: "Greek" },
            { code: "hu", name: "Hungarian" },
            { code: "it", name: "Italian" },
            { code: "ja", name: "Japanese" },
            { code: "pl", name: "Polish" },
            { code: "pt", name: "Portuguese" },
            { code: "ru", name: "Russian" },
            { code: "es", name: "Spanish" },
            { code: "sv", name: "Swedish" },
            { code: "tr", name: "Turkish" }
        ]
    },
    mutations: {
        setLayout(state, payload) {
            state.layout = payload;
        },
        toggleSideBar(state, value) {
            state.is_show_sidebar = value;
        },
        toggleSearch(state, value) {
            state.is_show_search = value;
        },
        toggleLocale(state, value) {
            value = value || "en";
            i18n.locale = value;
            localStorage.setItem("i18n_locale", value);
            state.locale = value;
        },

        toggleDarkMode(state, value) {
            //light|dark|system
            value = value || "light";
            localStorage.setItem("dark_mode", value);
            state.dark_mode = value;
            if (value == "light") {
                state.is_dark_mode = false;
            } else if (value == "dark") {
                state.is_dark_mode = true;
            } else if (value == "system") {
                if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
                    state.is_dark_mode = true;
                } else {
                    state.is_dark_mode = false;
                }
            }

            if (state.is_dark_mode) {
                document.querySelector("body").classList.add("dark");
            } else {
                document.querySelector("body").classList.remove("dark");
            }
        },

        toggleMenuStyle(state, value) {
            //horizontal|vertical|collapsible-vertical
            value = value || "";
            localStorage.setItem("menu_style", value);
            state.menu_style = value;
            if (!value || value === "vertical") {
                state.is_show_sidebar = true;
            } else if (value === "collapsible-vertical") {
                state.is_show_sidebar = false;
            }
        },

        toggleLayoutStyle(state, value) {
            //boxed-layout|large-boxed-layout|full
            value = value || "";
            localStorage.setItem("layout_style", value);
            state.layout_style = value;
        },
        setLogin(state, value) {
            state.isLogged = value;
        },
        setAccount(state, value) {
            if(value){
                for(let fix of value.contacts){
                    delete fix.aff_id;
                    delete fix.created_at;
                    delete fix.updated_at;
                    delete fix.user_id;
                }
                value.avatar = {src: null}
            }
            state.account = value;
        }
    },
    getters: {
        layout(state) {
            return state.layout;
        },
        isLogged(state) {
            return state.account && state.account["public_key"];
        },
        account(state) {
            return state.account;
        },
        balance(state) {
            if (!state.balance) {
                store.dispatch("getBalance");
            }
            return state.balance;
        },
        managers(state) {
            if (isEmpty(state.managers)) {
                store.dispatch("getManagers");
            }
            return state.managers;
        },
        messengers(state) {
            if (isEmpty(state.messengers)) {
                store.dispatch("getMessengers");
            }
            return state.messengers;
        },
        iabCategories(state){
            if (isEmpty(state.iabCategories)) {
                store.dispatch("getIabCategories");
            }
            return state.iabCategories
        },
        safe_domain(state) {
            if (isEmpty(state.domains.safe_domain)) {
                store.dispatch("getSafeDomain");
            }
            return state.domains.safe_domain
        },
        cdn_domain(state) {
            if (isEmpty(state.domains.cdn_domain)) {
                store.dispatch("getCdnDomain");
            }
            return state.domains.cdn_domain
        },
        promo_domain(state) {
            if (isEmpty(state.domains.promo_domain)) {
                store.dispatch("getPromoDomain");
            }
            return state.domains.promo_domain
        },
    },
    actions: {
        login(context, data) {
            context.commit("setLogin", true);
            context.commit("setAccount", data);
            context.dispatch("dashboard/load");
        },
        logout(context) {
            context.commit("setAccount", null);
            context.commit("setLogin", false);
            // localStorage.removeItem("authTokenSt");
            Vue.$cookies.remove(appSettings.authToken);
            source.defaults.headers.common["Authorization"] = "";
        },
        getManagers(context) {
            source.get("/contacts/m10").then(response => {
                context.state.managers = response.data;
            });
        },
        getMessengers(context) {
            source.get("/contacts").then(response => {
                let data = response.data;
                for (let el in data) {
                    data[el].name = data[el].type.replace("_", " ");
                    switch (data[el].name) {
                        case "icq":
                            data[el].name = "ICQ";
                            break;
                        case "fb messenger":
                            data[el].name = "FB messenger";
                            break;
                        default:
                            data[el].name = data[el].name
                                .toLowerCase()
                                .replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()));
                            break;
                    }
                }
                context.state.messengers = data;
            });
        },
        getBalance(context) {
            if (context.state.account) {
                source.get("/webmasters/" + context.state.account.id + "/finance/balance").then(response => {
                    context.state.balance = response.data;
                });
            }
        },
        getIabCategories(context){
            source.get("/iab_categories/names").then(response => {
                context.state.iabCategories = response.data;
            });
        },
        saveContacts(context, newData) {
            if (newData) {
                let result = context.state.account.contacts;
                for (let index in newData) {
                    if (newData[index]) {
                        // if(!result[index].created_at){
                        //     result[index].created_at = (new Date()).toISOString()
                        // }
                        // result[index].updated_at = (new Date()).toISOString()
                        // result[index].user_id = context.state.account.user_id;
                        if(newData[index].id){
                            result[index].type_id = newData[index].id;
                        }
                        result[index].type = newData[index].type;
                        result[index].contact = newData[index].contact;
                    }
                }
                context.dispatch('saveAccount', {contacts: result})
            }
        },
        saveRequisites(context, newData){
            if (newData) {
                context.dispatch('saveAccount', { requisites: newData })
            }
        },
        saveIntegrations(context, newData){
            if(newData){
                context.dispatch('saveAccount', { postback_url: newData.postback_url, trafficback_url: newData.trafficback_url })
            }
        },
        saveAccount(context, data){
            let account = {...context.state.account, ...data}
            context.state.account = account;
            source.put("/webmasters/information", account).then(() => {
                context.state.account = account;
            });
        },
        // savePassword(context, data){
        //     let account = {...context.state.account, ...data}
        // },
        getSafeDomain(context){
            source.get("/safe-domain").then((response)=>{
                context.state.domains.safe_domain = response.data.safe_domain
            })
        },
        getCdnDomain(context){
            source.get("/cdn-domain").then((response)=>{
                context.state.domains.cdn_domain = response.data.cdn_domain
            })
        },
        getPromoDomain(context){
            source.get("/promo-domain").then((response)=>{
                context.state.domains.promo_domain = response.data.promo_domain
            })
        }
    },
    modules: {
        dashboard
    }
});

export default store;
